import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { ClickAwayListener, Collapse, List } from "@mui/material";
import React, { useState } from "react";
import { NavLink, NavLinkProps, useMatch } from "react-router-dom";

import SidebarItem from "./SidebarItem";

export type SidebarLinkProps = {
  label: string;
  to: string;
  icon: React.ReactElement;
  nested?: SidebarLinkProps[];
  selected?: boolean;
} & Omit<NavLinkProps, "to">;

const SidebarLink = ({
  label,
  icon,
  to,
  nested,
  selected,
  ...props
}: SidebarLinkProps) => {
  const isActive = !!useMatch({
    path: to,
  });
  const isMatching =
    !!useMatch({
      path: `${to}/*`,
    }) && to !== "/";
  const [open, setOpen] = useState(isMatching);

  const isSelected = isMatching || selected;

  if (nested) {
    return (
      <>
        <SidebarItem
          label={label}
          icon={icon}
          onClick={() => setOpen(!open)}
          selected={isSelected}
          component="div"
        >
          {open ? (
            <ExpandLessOutlinedIcon style={{ fontSize: 16 }} />
          ) : (
            <ExpandMoreOutlinedIcon style={{ fontSize: 16 }} />
          )}
        </SidebarItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ClickAwayListener onClickAway={() => isSelected || setOpen(false)}>
            <List component="div" disablePadding>
              {nested?.map((linkProps) => (
                <SidebarLink
                  key={linkProps.label}
                  {...linkProps}
                  selected={isSelected || open}
                />
              ))}
            </List>
          </ClickAwayListener>
        </Collapse>
      </>
    );
  }

  return (
    <NavLink
      {...props}
      to={to}
      style={{
        color: "inherit",
        textDecoration: "none",
      }}
    >
      <SidebarItem
        selected={isSelected}
        isActive={isActive}
        label={label}
        icon={icon}
        component="div"
      />
    </NavLink>
  );
};

export default SidebarLink;
