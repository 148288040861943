import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Skeleton,
  Button,
  Avatar,
  ListItemIcon,
} from "@mui/material";
import Box from "@mui/material/Box";
import { memo, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import ButtonLink from "../../ButtonLink";
import { getMenuForSearch } from "../Sidebar/navLinks";

import AsyncTasks from "./AsyncTasks";
import GlobalSearch from "./GlobalSearch";

import PinionBlueSvg from "@/assets/PINION-BLUE.png";
import { useAuth } from "@/modules/auth/AuthContext";
import { useThemeModeStore } from "@/theme";

const ToggleThemeMenuItem = memo(function ToggleThemeMenuItemComponent() {
  const { mode, toggleMode } = useThemeModeStore();
  return (
    <MenuItem sx={{ textTransform: "capitalize" }} onClick={toggleMode}>
      <ListItemIcon>
        <IconButton sx={{ p: 0, mr: 3 }} color="inherit">
          {mode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
        </IconButton>
      </ListItemIcon>
      {mode === "light" ? "Dark Mode" : "Light Mode"}
    </MenuItem>
  );
});

export const Topbar = () => {
  const { logout, user } = useAuth();
  const flatMenu = useMemo(
    () => getMenuForSearch(user?.menu || []),
    [user?.menu]
  );
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const firstLetter = user?.name?.charAt(0).toUpperCase();

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <ButtonLink
          size="large"
          color="primary"
          LinkComponent={Link}
          to="/"
          sx={{ mr: 2 }}
        >
          <img src={PinionBlueSvg} alt="Pinion" height="28px" />
        </ButtonLink>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              mr: {
                xs: 0,
                sm: 1,
              },
            }}
          >
            <GlobalSearch flatMenu={flatMenu} />
          </Box>
          <AsyncTasks />
          <IconButton
            onClick={handleOpenUserMenu}
            color="default"
            sx={{ p: 1 }}
          >
            <Avatar
              src={user?.image}
              alt={user?.name}
              sx={{
                height: 36,
                width: 36,
              }}
            >
              {firstLetter}
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
          >
            <MenuItem
              component={Link}
              to="/profile"
              onClick={handleCloseUserMenu}
            >
              <Avatar
                src={user?.image}
                alt={user?.name}
                sx={{
                  width: 32,
                  height: 32,
                  mr: 2,
                }}
              >
                {firstLetter}
              </Avatar>
              {user?.name}
            </MenuItem>
            <Divider />
            <ToggleThemeMenuItem />
            <MenuItem
              onClick={logout}
              sx={{
                minWidth: 200,
              }}
            >
              <ListItemIcon>
                <LogoutIcon
                  sx={{
                    mr: 3,
                  }}
                />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export const TopbarSkeleton = () => {
  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <Button size="large" color="primary" sx={{ mr: 2 }}>
          <img src={PinionBlueSvg} alt="Pinion" height="28px" />
        </Button>
        <Box
          sx={{
            ml: "auto",
          }}
        >
          <IconButton sx={{ p: 1 }}>
            <Skeleton variant="circular" width={36} height={36} />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};
