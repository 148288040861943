import { Grid, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect } from "react";

type Props = {
  children?: React.ReactNode;
  actions?: React.ReactNode;
  title?: string;
  icon?: React.ElementType;
  disablePadding?: boolean;
  isLoading?: boolean;
  pageId?: string;
};

const TitledPage = ({
  children,
  actions,
  title,
  icon: Icon,
  disablePadding,
  isLoading,
  pageId,
}: Props) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  return (
    <Grid
      container
      id={pageId}
      sx={{
        p: disablePadding ? 0 : 3,
        gap: 3,
        bgcolor: (theme) => theme.palette.background.default,
      }}
    >
      <Grid item xs={12}>
        <Stack
          justifyContent="space-between"
          sx={{ flexDirection: { xs: "column", md: "row" }, gap: { xs: 2 } }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            {isLoading ? (
              <Skeleton variant="circular" width={30} height={30} />
            ) : (
              Icon && <Icon style={{ fontSize: 30 }} />
            )}
            <Typography
              variant="h5"
              component="h2"
              sx={{
                overflowWrap: "anywhere",
              }}
            >
              {isLoading ? <Skeleton width={200} /> : title}
            </Typography>
          </Stack>
          <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 4 },
              alignItems: { xs: "initial", sm: "center" },
              flex: 1,
              justifyContent: { xs: "initial", sm: "flex-end" },
            }}
          >
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                height={40}
                sx={{
                  width: { xs: "100%", sm: 160 },
                }}
              />
            ) : (
              actions
            )}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: {
                xs: "calc(100vh - 225px)",
                sm: "calc(100vh - 178px)",
              },
            }}
          />
        ) : (
          children
        )}
      </Grid>
    </Grid>
  );
};

export default TitledPage;
