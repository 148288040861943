import { create } from "zustand";
import { persist } from "zustand/middleware";

export const defaultRowsPerPageOptions = [5, 10, 25, 50];

export const useTableDefaultRows = create<{
  defaultRows: number;
  setDefaultRows: (rows: number) => void;
}>()(
  persist(
    (set) => ({
      defaultRows: 10,
      setDefaultRows: (rows) => set({ defaultRows: rows }),
    }),
    {
      name: "default-table-rows",
    }
  )
);
