import DarkModeIcon from "@mui/icons-material/DarkMode";
import HistoryIcon from "@mui/icons-material/History";
import LightModeIcon from "@mui/icons-material/LightMode";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import TableRowsIcon from "@mui/icons-material/TableRows";
import {
  Autocomplete,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  Backdrop,
  Box,
  CircularProgress,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { matchSorter } from "match-sorter";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";
import { persist } from "zustand/middleware";

import KeybindHint from "../../KeybindHint";
import {
  defaultRowsPerPageOptions,
  useTableDefaultRows,
} from "../../table/useTableDefaultRows";
import { FlattenedItem } from "../Sidebar/navLinks";

import Global, { SearchResultItem } from "@/modules/common/Global";
import isAppleDevice from "@/modules/common/utils/isAppleDevice";
import { isValidKeysInput } from "@/modules/common/utils/isElement";
import { useThemeModeStore } from "@/theme";

const contextMap: Record<
  string,
  {
    route: string;
    tagColors: string[];
  }
> = {
  user: {
    route: "/users",
    tagColors: ["deepPurple", "pink", "indigo", "teal", "orange", "green"],
  },
  auser: {
    route: "/ausers",
    tagColors: ["deepPurple", "teal", "pink", "indigo", "orange", "green"],
  },
  customer: {
    route: "/customers",
    tagColors: ["indigo", "deepPurple", "pink", "teal", "orange", "green"],
  },
  mission: {
    route: "/missions",
    tagColors: ["teal", "deepPurple", "indigo", "pink", "orange", "green"],
  },
  polygon: {
    route: "/locations/explorer",
    tagColors: ["indigo", "teal", "pink", "deepPurple", "orange", "green"],
  },
  region: {
    route: "/locations/regions",
    tagColors: ["indigo", "deepPurple", "pink", "teal", "orange", "green"],
  },
  project: {
    route: "/projects",
    tagColors: ["indigo", "teal", "pink", "deepPurple", "orange", "green"],
  },
};

type RecentSearchItem = {
  to: string;
  label: string;
  type: string;
  context?: string;
  tags?: Record<string, string | number>;
};

const useRecentSearchs = create<{
  recents: RecentSearchItem[];
  addRecent: (search: RecentSearchItem) => void;
}>()(
  persist(
    (set) => ({
      recents: [],
      addRecent: (search) =>
        set((state) => ({
          recents: [
            search,
            ...state.recents.filter((s) => s.to !== search.to),
          ].slice(0, 5),
        })),
    }),
    {
      name: "recent-searchs",
    }
  )
);

type GlobalSearchItem = FlattenedItem & SearchResultItem;

type CommandItem = {
  label: string;
  onSelect: () => void;
  icon: React.ReactNode;
  description?: React.ReactNode;
  type: string;
};

type Props = {
  flatMenu: FlattenedItem[];
};
const MIN_LENGTH_TO_SEARCH = 3;
const OBJECTID_LENGTH = 24;

export function useDebounceTyping(
  value: string,
  delay: number = 500
): [string, boolean] {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  const [isTyping, setIsTyping] = React.useState(true);

  React.useEffect(() => {
    setIsTyping(true);
    const handler: NodeJS.Timeout = setTimeout(() => {
      setDebouncedValue(value);
      setIsTyping(false);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, isTyping];
}

const GlobalSearch = memo(function GS({ flatMenu }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState("");
  const [debouncedSearch, isTyping] = useDebounceTyping(search, 2000);
  const { recents, addRecent } = useRecentSearchs();
  const [commandOptions, setCommandOptions] = useState<CommandItem[]>([]);
  const theme = useThemeModeStore();
  const tableDefaultRows = useTableDefaultRows();
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const isCommand = search.startsWith(">");

  const clientMenu = useMemo(() => {
    if (isCommand) {
      if (commandOptions.length > 0) {
        return commandOptions;
      }

      return matchSorter(
        [
          {
            label: "Switch theme",
            onSelect: () => theme.toggleMode(),
            icon: theme.mode === "light" ? <DarkModeIcon /> : <LightModeIcon />,
            type: "Commands",
            description: (
              <>
                <strong>To: </strong>
                {theme.mode === "light" ? "Dark Mode" : "Light Mode"}
              </>
            ),
          } as CommandItem,
          {
            label: "Change default table rows",
            onSelect: () => {
              setCommandOptions(
                defaultRowsPerPageOptions.map((rows) => ({
                  label: rows.toString(),
                  onSelect: () => {
                    tableDefaultRows.setDefaultRows(rows);
                    setCommandOptions([]);
                  },
                  icon: <TableRowsIcon />,
                  type: "Select the number of rows tables should display by default (page refresh required)",
                }))
              );
            },
            icon: <TableRowsIcon />,
            type: "Commands",
            description: (
              <>
                <strong>Current: </strong>
                {tableDefaultRows.defaultRows}
              </>
            ),
          },
        ] as CommandItem[],
        search.slice(1),
        {
          keys: ["label", "description"],
        }
      );
    }

    const recentSearches = recents.map((search) => ({
      ...search,
      icon: <HistoryIcon sx={{ opacity: 0.8 }} />,
    })) as FlattenedItem[];

    if (search.trim().length === 0) {
      return recentSearches.concat(flatMenu);
    }

    const recentSearchesFiltered = matchSorter(recentSearches, search, {
      keys: ["label", "to"],
    });
    let flatMenuFiltered = matchSorter(flatMenu, search, {
      keys: ["searchable", "label", "to"],
    });

    if (search.trim().length >= MIN_LENGTH_TO_SEARCH) {
      flatMenuFiltered = flatMenuFiltered.slice(0, 5);
    }

    return recentSearchesFiltered.concat(flatMenuFiltered);
  }, [
    flatMenu,
    recents,
    search,
    isCommand,
    theme,
    tableDefaultRows,
    commandOptions,
  ]);

  const {
    data: searchMenu,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ["global-search", debouncedSearch],
    queryFn: () => {
      if (debouncedSearch.trim().length < MIN_LENGTH_TO_SEARCH) {
        return Promise.resolve([]);
      }
      return Global.search(debouncedSearch).then((res) =>
        res.map(
          (item: SearchResultItem): GlobalSearchItem => ({
            ...item,
            to: `${contextMap[item.context]?.route ?? `/${item.context}s`}/${
              item.id
            }`,
            label: item.title,
            icon: <SearchIcon />,
            type: item.context.charAt(0).toUpperCase() + item.context.slice(1),
          })
        )
      );
    },
    throwOnError: false,
  });

  useEffect(() => {
    if (searchMenu?.length === 1 && search.trim().length === OBJECTID_LENGTH) {
      const item = searchMenu[0];
      navigate(item.to!);
      inputRef.current?.blur();
      addRecent({
        to: item.to!,
        label: item.label,
        type: "Recents",
        tags: item.tags,
        context: item.context,
      });
    }
  }, [searchMenu, navigate, addRecent, search]);

  const finalMenu: GlobalSearchItem[] = useMemo(() => {
    if (isCommand && !clientMenu.length) {
      return [
        {
          label: "No command found",
          search: search,
          icon: <NotInterestedIcon />,
          disabled: true,
          type: "Commands",
        },
      ] as GlobalSearchItem[];
    }

    if (isCommand) {
      return clientMenu as GlobalSearchItem[];
    }

    if (isLoading || isTyping) {
      return [
        ...clientMenu,
        {
          label: "Searching...",
          search: search,
          icon: <SearchIcon />,
          disabled: true,
          type: "Global Search",
        },
      ] as GlobalSearchItem[];
    }

    const menu = [...clientMenu, ...(searchMenu ?? [])];

    if (searchMenu?.length) {
      return menu as GlobalSearchItem[];
    }

    if (search.trim().length < MIN_LENGTH_TO_SEARCH) {
      menu.push({
        label: "Type at least 3 characters to search",
        search: search,
        icon: <SearchOffIcon />,
        disabled: true,
        type: "Global Search",
      });

      return menu as GlobalSearchItem[];
    }

    if (clientMenu.length == 0) {
      menu.unshift({
        label: "No results found",
        search: search,
        icon: <SearchOffIcon />,
        disabled: true,
        type: "Global Search",
      });
    }

    return menu as GlobalSearchItem[];
  }, [clientMenu, searchMenu, isLoading, isTyping, search, isCommand]);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        (e.target as HTMLElement)?.blur();
      }

      const cmdKey = isAppleDevice ? e.metaKey : e.ctrlKey;
      const shiftKey = e.shiftKey;

      if (cmdKey && e.key === "k" && !shiftKey) {
        e?.preventDefault();
        isFocused ? inputRef.current?.blur() : inputRef.current?.focus();
      }

      if (cmdKey && e.key === "k" && shiftKey) {
        e?.preventDefault();
        if (isFocused && isCommand) {
          inputRef.current?.blur();
        } else {
          inputRef.current?.focus();
          setSearch(">");
        }
      }

      if (isValidKeysInput(e.target)) {
        return;
      }
    };
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [inputRef, isFocused, isCommand]);

  const handleOptionSelect = (
    event: React.SyntheticEvent,
    newValue: GlobalSearchItem | CommandItem | null
  ) => {
    if ((newValue as CommandItem)?.onSelect) {
      (newValue as CommandItem).onSelect();
      event.stopPropagation();
      return;
    }

    const searchValue = newValue as GlobalSearchItem;
    if (searchValue?.to) {
      navigate(searchValue.to!);
      inputRef.current?.blur();
      addRecent({
        to: searchValue.to!,
        label: searchValue.label,
        type: "Recents",
        tags: searchValue.tags,
        context: searchValue.context,
      });
    }
  };

  const handleFocus = () => setIsFocused(true);

  const handleBlur = () => {
    setIsFocused(false);
    setSearch("");
    setCommandOptions([]);
  };

  const handleInputChange = (
    event: React.SyntheticEvent,
    newValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === "reset") {
      return event?.preventDefault();
    }
    if (newValue.trim().length === 0) {
      setCommandOptions([]);
    }
    setSearch(newValue);
  };

  const handleClose = (
    _: React.SyntheticEvent,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === "escape") {
      inputRef.current?.blur();
    }
  };

  return (
    <>
      <Autocomplete<GlobalSearchItem>
        options={finalMenu}
        open={isFocused}
        ListboxProps={{ style: { maxHeight: "80vh" } }}
        renderOption={(props, option) => {
          return (
            <GlobalSearchOption
              {...props}
              option={option}
              key={`${option.to ?? option.context}-${option.id}-${
                option.label
              }`}
            />
          );
        }}
        getOptionLabel={(option) => option.search ?? option.label}
        getOptionDisabled={(option) => option.disabled ?? false}
        filterOptions={(options) => options}
        onChange={handleOptionSelect}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={null}
        onInputChange={handleInputChange}
        inputValue={search}
        disableCloseOnSelect
        openOnFocus
        autoHighlight
        groupBy={(option) => option.type}
        onClose={handleClose}
        size="small"
        sx={{
          ml: "auto",
          maxWidth: "42rem",
          "&:focus-within": {
            maxWidth: "100%",
            position: {
              xs: "fixed",
              sm: "sticky",
            },
            zIndex: (theme) => theme.zIndex.snackbar,
            right: 8,
            top: 8,
            left: 8,
          },
          backgroundColor: "background.paper",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={search}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Box
                  sx={{
                    display: "flex",
                    mr: -2,
                  }}
                >
                  {!isFocused ? (
                    <>
                      <KeybindHint kbd={isAppleDevice ? "⌘" : "Ctrl"} />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        mx="2px"
                      >
                        {"+"}
                      </Typography>
                      <KeybindHint kbd="K" />
                    </>
                  ) : isFetching ? (
                    <CircularProgress size={20} />
                  ) : (
                    <KeybindHint kbd="esc" bold={false} />
                  )}
                </Box>
              ),
            }}
            inputRef={inputRef}
            placeholder="Global Search"
            fullWidth
          />
        )}
      />
      <Backdrop
        open={isFocused}
        sx={{ zIndex: (theme) => theme.zIndex.modal }}
      />
    </>
  );
});

type GlobalSearchOptionProps = {
  option: GlobalSearchItem;
} & React.HTMLAttributes<HTMLLIElement>;
const GlobalSearchOption = ({ option, ...props }: GlobalSearchOptionProps) => {
  const tagColors = contextMap[option.context]?.tagColors ?? [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const themeColors = colors as Record<string, any>;
  return (
    <Box
      component="li"
      sx={{
        "& svg": {
          mr: 3,
        },
        "& > span": {
          fontWeight: 500,
        },
        "& .focusable": {
          visibility: "hidden",
        },
        "&.MuiAutocomplete-option.Mui-focused .focusable": {
          visibility: {
            sm: "visible",
          },
        },
      }}
      {...props}
    >
      {option.icon}
      <Box>
        <Box display="flex" columnGap={1} alignItems="center" flexWrap="wrap">
          {option.label}
          {option.id && (
            <Typography
              variant="body2"
              color="textSecondary"
              display="inline-block"
            >
              {option.id}
            </Typography>
          )}
        </Box>
        <small>
          <code>
            {option.to && <strong>Go to: </strong>}
            {option.search && <strong>Query: </strong>}
            {option.to ??
              option.search ??
              (option as unknown as CommandItem).description}
          </code>
        </small>
        {Object.keys(option.tags ?? {}).length > 0 && (
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            flexWrap="wrap"
            my={1}
          >
            {Object.entries(option.tags ?? {}).map(([key, value], index) => {
              const tagColor = tagColors[index] ?? "indigo";
              return (
                <Box
                  sx={{
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                    border: `1px solid ${themeColors[tagColor][700]}`,
                  }}
                  key={key}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      backgroundColor: themeColors[tagColor][700],
                      color: (theme) =>
                        theme.palette.getContrastText(
                          themeColors[tagColor][700]
                        ),
                      fontWeight: 500,
                      px: 1,
                      py: "2px",
                    }}
                  >
                    {key}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      backgroundColor: themeColors[tagColor][800],
                      color: (theme) =>
                        theme.palette.getContrastText(
                          themeColors[tagColor][800]
                        ),
                      px: 1,
                      py: "2px",
                    }}
                  >
                    {value.toString()}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      <Box
        className="focusable"
        sx={{
          ml: "auto",
          width: "52px",
        }}
      >
        {!option.disabled && option.to && (
          <Typography variant="body2" color="textSecondary">
            <KeybindHint kbd="Enter" />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default GlobalSearch;
