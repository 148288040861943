import { useCallback } from "react";

import formatDatetime, {
  DEFAULT_FULL_DATETIME_OPTIONS,
} from "../utils/formatDatetime";

import { useAuth } from "@/modules/auth/AuthContext";

const useDateFormatter = () => {
  const { user } = useAuth();

  const datetimeFormatter = useCallback(
    (date: Date, options: Intl.DateTimeFormatOptions) => {
      return formatDatetime(date, user?.locale, {
        ...options,
        timeZone: user?.timezone,
      });
    },
    [user]
  );

  return {
    DEFAULT_FULL_DATETIME_OPTIONS,
    datetimeFormatter,
  };
};

export default useDateFormatter;
