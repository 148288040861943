import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  IconButton,
  Badge,
  Box,
  Button,
  Popover,
  Divider,
  Typography,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { memo, useRef, useState } from "react";

import notificationSound from "./notification.mp3";

import Global, { Task } from "@/modules/common/Global";
import useDateFormatter from "@/modules/common/hooks/useDateFormatter";
import { useToast } from "@/modules/common/lib/toast";

const notificationAudio = new Audio(notificationSound);
const POOLING_INTERVAL = 1000 * 5;

const AsyncTasks = memo(function AsyncTasksComponent() {
  const addToast = useToast();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleDownloadFile = (taskId: string, actionIndex: number) => {
    Global.tasksDownloadUrl(taskId, actionIndex).then((data) => {
      window.open(data.url, "_blank", "noopener, noreferrer");
      handleClose();
    });
  };

  const tasksCountQuery = useQuery({
    queryKey: ["tasks", "count"],
    queryFn: () =>
      Global.tasksCount().then((res) => {
        const hasNewUnseenTasks =
          res.unseen > (tasksCountQuery.data?.unseen ?? 0);
        const hasNewProcessingTasks =
          res.processing > (tasksCountQuery.data?.processing ?? 0);
        const shouldRefetch =
          isOpen && (hasNewUnseenTasks || hasNewProcessingTasks);

        if (shouldRefetch) {
          tasksListQuery.refetch();
        }

        if (hasNewUnseenTasks) {
          notificationAudio.play().catch(() => undefined);
          addToast({
            type: "info",
            message: "Task completed",
            alertProps: {
              icon: <HourglassBottomIcon fontSize="inherit" />,
              action: (
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  View
                </Button>
              ),
            },
          });
        }

        return res;
      }),
    refetchInterval: (query) => {
      return query.state.status === "error" ? false : POOLING_INTERVAL;
    },
    throwOnError: false,
  });

  const tasksListQuery = useQuery({
    queryKey: ["tasks", "list"],
    queryFn: () =>
      Global.tasks().then((res) => {
        tasksCountQuery.refetch();
        return res;
      }),
    enabled: isOpen,
    throwOnError: false,
  });

  const countUnseen = tasksCountQuery.data?.unseen ?? 0;
  const countProcessing = tasksCountQuery.data?.processing ?? 0;
  const hasUnseenFinished = countUnseen > 0;
  const hasProcessingTasks = countProcessing > 0;

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          p: 1.5,
          mx: {
            xs: 0.5,
            sm: 1,
          },
        }}
      >
        <Badge
          badgeContent={countUnseen || countProcessing}
          color={hasUnseenFinished ? "primary" : "warning"}
        >
          {tasksListQuery.isFetching ? (
            <CircularProgress size={24} color="inherit" />
          ) : hasUnseenFinished ? (
            <HourglassBottomIcon />
          ) : hasProcessingTasks ? (
            <HourglassTopIcon />
          ) : (
            <HourglassEmptyIcon
              sx={{
                opacity: 0.8,
              }}
            />
          )}
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: 400,
              maxWidth: 500,
              maxHeight: "80%",
            },
          },
        }}
      >
        <TasksList
          onDownloadFile={handleDownloadFile}
          isLoading={tasksListQuery.isLoading}
          tasks={tasksListQuery.data}
          isEmpty={
            !tasksListQuery.isLoading && tasksListQuery.data?.length === 0
          }
        />
      </Popover>
    </>
  );
});

const TasksList = ({
  onDownloadFile,
  isLoading,
  isEmpty,
  tasks,
}: {
  onDownloadFile: (taskId: string, actionIndex: number) => void;
  isLoading: boolean;
  isEmpty: boolean;
  tasks?: Task[];
}) => {
  const { datetimeFormatter, DEFAULT_FULL_DATETIME_OPTIONS } =
    useDateFormatter();
  return (
    <Box>
      <Divider />
      {isLoading && (
        <Box px={2} py={3} display="flex" justifyContent="center">
          Loading tasks...
        </Box>
      )}
      {isEmpty && (
        <Box px={2} py={3} display="flex" justifyContent="center">
          There are no tasks yet.
        </Box>
      )}
      {tasks?.map((task) => {
        const unseenColor = task.status === "failed" ? "error" : "primary";
        const statusColor = ({
          queued: "inherit",
          processing: "warning",
          failed: "error",
          finished: "success",
        }[task.status] ?? "inherit") as
          | "inherit"
          | "warning"
          | "error"
          | "success";

        return (
          <Box
            key={task.id}
            sx={{
              p: 2,
              border: (theme) => `1px solid ${theme.palette.divider}`,
              backgroundColor: (theme) =>
                task.seen
                  ? "transparent"
                  : `${theme.palette[unseenColor].main}25`,
            }}
          >
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="body1">{task.title}</Typography>
              </Box>
              {task.seen || (
                <Box
                  borderRadius="50%"
                  bgcolor={`${unseenColor}.main`}
                  width={8}
                  height={8}
                  ml={1}
                />
              )}
            </Box>
            <Typography
              variant="caption"
              color="text.secondary"
              fontSize={11}
              variantMapping={{
                caption: "div",
              }}
            >
              {datetimeFormatter(
                new Date(task.updated),
                DEFAULT_FULL_DATETIME_OPTIONS
              )}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                my: 1,
                opacity: 0.8,
              }}
            >
              {task.text}
            </Typography>
            <Box
              sx={{
                flex: 1,
                height: 4,
                borderRadius: 2,
                mb: 5,
              }}
            >
              <Typography
                variant="caption"
                color="text.secondary"
                fontSize={12}
              >
                Status: <strong>{task.status.toUpperCase()}</strong>
              </Typography>
              <LinearProgress
                color={statusColor}
                variant={
                  task.status === "processing" ? "indeterminate" : "determinate"
                }
                value={task.status === "queued" ? 10 : 100}
              />
            </Box>

            {task.actions.length > 0 && (
              <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  fontSize={12}
                  mr={3}
                >
                  Actions:
                </Typography>
                {task.actions.map((action, index) =>
                  action.type === "file" ? (
                    <Button
                      key={`${task.id} ${action.title}`}
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={() => onDownloadFile(task.id, index)}
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      {action.title}
                    </Button>
                  ) : null
                )}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default AsyncTasks;
