import { Box, colors } from "@mui/material";
import { useMemo } from "react";

type Props = { kbd: string; bold?: boolean };
const KeybindHint = ({ kbd, bold = true }: Props) => {
  const kbdAlignStyles = useMemo(() => {
    if (kbd === "⌘") {
      return {
        pt: "1.5px",
      };
    }
    return {
      pb: "1px",
    };
  }, [kbd]);

  return (
    <Box
      component="kbd"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "dark"
            ? colors.blueGrey[900]
            : colors.blueGrey[100],
        borderRadius: "4px",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        boxShadow: (theme) => `inset 0 -1px 0 ${theme.palette.divider}`,
        color: "text.primary",
        display: "inline-block",
        fontWeight: bold ? "bold" : "normal",
        lineHeight: "16px",
        px: "3px",
        ...kbdAlignStyles,
      }}
    >
      {kbd}
    </Box>
  );
};
export default KeybindHint;
