import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

export function ErrorFallback() {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            width: "100%",
            maxWidth: 448,
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f00",
            color: "#fff",
            margin: "20px",
          }}
        >
          <Typography variant="h6">Something went wrong.</Typography>
          <Typography mb={4}>Try to solve reloading the page.</Typography>
          <br />
          <br />
          <Button variant="custom-error" onClick={handleReload}>
            Reload
          </Button>
        </Paper>
      </Box>
    </>
  );
}
