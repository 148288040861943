import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import React from "react";

import { FetcherError } from "./modules/common/lib/httpClient/fetcher";
import { useToast } from "./modules/common/lib/toast";
import { NewToast } from "./modules/common/lib/toast/ToastContext";

export let queryClient: QueryClient | null = null;

export const standardSuccessMutationToasts: NewToast = {
  type: "success",
  message: "Saved successfully",
};

export const createStandardErrorToast: (error: Error) => NewToast = (
  error
) => ({
  type: "error",
  message: `Something went wrong: ${error.message}`,
});

export const AppQueryClient = ({ children }: { children: React.ReactNode }) => {
  const addToast = useToast();
  queryClient = React.useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError(error, query) {
            if (
              // reset cache error after redirect to login
              (error as unknown as FetcherError)?.status === 401 &&
              query.queryKey[0] !== "me"
            ) {
              setTimeout(() => {
                queryClient?.removeQueries({
                  queryKey: query.queryKey,
                });
              }, 1000);
            }
            if (query.queryKey[0] !== "me") {
              addToast(createStandardErrorToast(error as Error));
            }
          },
        }),
        // This options can be overridden in each individual query/mutation options
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 1,
            retryDelay: 2400,
            throwOnError: (error: unknown) =>
              [403, 404, 500].includes((error as FetcherError).status),
          },
          mutations: {
            onSuccess: () => {
              addToast(standardSuccessMutationToasts);
            },
            onError: (error) => {
              addToast(createStandardErrorToast(error as Error));
            },
            throwOnError: (error: unknown) =>
              (error as FetcherError).status === 403,
          },
        },
      }),
    [addToast]
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
