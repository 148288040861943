import { ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import TitledPage from "./pages/TitledPage";
import { Sidebar, SidebarSkeleton } from "./Sidebar";
import { Topbar, TopbarSkeleton } from "./Topbar";

import getTheme from "@/theme";

const theme = getTheme("dark");

const AppLayout = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Topbar />
      </ThemeProvider>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: {
            xs: "calc(100vh - 56px)",
            sm: "calc(100vh - 65px)",
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <Sidebar />
        </ThemeProvider>
        <Box
          sx={{
            width: "inherit",
            flex: "1 1 0%",
            overflow: "auto",
            paddingBottom: {
              xs: 7,
              sm: 0,
            },
          }}
        >
          <Suspense fallback={<TitledPage isLoading />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </>
  );
};

export const AppLayoutSkeleton = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <TopbarSkeleton />
      </ThemeProvider>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: {
            xs: "100vh",
            sm: "calc(100vh - 65px)",
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <SidebarSkeleton />
        </ThemeProvider>
        <Box
          sx={{
            width: "inherit",
            flex: "1 1 0%",
            paddingBottom: {
              xs: 7,
              sm: 0,
            },
            overflow: "auto",
          }}
        >
          <TitledPage isLoading />
        </Box>
      </Box>
    </>
  );
};

export default AppLayout;
