import { config } from "@/config";
import Page404 from "@/modules/common/components/layout/pages/Page404";

const GoToAdm4 = () => {
  if (config.enviroment !== "local") {
    location.reload();
    return null;
  }

  return <Page404 />;
};
export default GoToAdm4;
