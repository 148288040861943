import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import updateLocale from "dayjs/plugin/updateLocale";
import weekday from "dayjs/plugin/weekday";
import React from "react";

import { AppQueryClient } from "./appQueryClient";
import { config } from "./config";
import { AuthProvider } from "./modules/auth/AuthContext";
import { ErrorFallback } from "./modules/common/components/ErrorFallback";
import I18nProvider from "./modules/common/components/I18nProvider";
import AppRouter from "./router/AppRouter";
import getTheme, { useThemeModeStore } from "./theme";

import { ToastProvider } from "@/modules/common/lib/toast";

dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});
dayjs.locale("en");

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.enviroment,
  enabled: !!config.sentry.dsn,
  release: `pinion.adm5@${config.version}`,
  ignoreErrors: [
    /Failed to fetch dynamically imported module/,
    /Non-Error exception captured with keys: message, status/,
  ],
});

const SentryBoundary = ({ children }: { children: React.ReactNode }) => {
  if (config.enviroment === "local") {
    return <>{children}</>;
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

const App = () => {
  const { mode } = useThemeModeStore();
  const theme = React.useMemo(() => {
    const theme = getTheme(mode);
    document.body.style.colorScheme = mode;
    return theme;
  }, [mode]);

  return (
    <ThemeProvider theme={theme}>
      <SentryBoundary>
        <ToastProvider>
          <AppQueryClient>
            <AuthProvider>
              <I18nProvider>
                <CssBaseline />
                <AppRouter />
              </I18nProvider>
            </AuthProvider>
          </AppQueryClient>
        </ToastProvider>
      </SentryBoundary>
    </ThemeProvider>
  );
};
export default App;
