import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { create } from "zustand";
import { persist } from "zustand/middleware";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    "custom-error": true;
  }
}

export type ThemeMode = "light" | "dark";

const getContainer = () => {
  return document.fullscreenElement ?? document.body;
};

const getTheme = (mode: ThemeMode = "dark") =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: "#0163ac",
      },
      secondary: {
        main: "#6a0dad",
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "custom-error" },
            style: {
              backgroundColor: "#fff",
              color: "#000",
              "&:hover": {
                backgroundColor: red["100"],
              },
            },
          },
        ],
      },
      MuiPopper: {
        defaultProps: {
          container: getContainer,
        },
      },
      MuiMenu: {
        defaultProps: {
          container: getContainer,
        },
      },
    },
  });

const themeModeStore = create<{
  mode: ThemeMode;
  toggleMode: () => void;
}>()(
  persist(
    (set) => ({
      mode: window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light",
      toggleMode: () =>
        set((state) => ({
          mode: state.mode === "dark" ? "light" : "dark",
        })),
    }),
    {
      name: "theme-mode",
    }
  )
);

export const useThemeModeStore = themeModeStore;

export default getTheme;
