import httpClient from "./lib/httpClient";
import { WithId } from "./types/sharedTypes";

export type SearchResultItem = {
  context: string;
  id: string;
  tags: Record<string, string>;
  title: string;
};

export type TaskAction = {
  title: string;
  type: "file";
};

export type TaskStatus = "queued" | "processing" | "finished" | "failed";

export type Task = WithId<{
  created: string;
  updated: string;
  title: string;
  status: TaskStatus;
  text: string;
  actions: TaskAction[];
  seen: boolean;
}>;

export type TaskCount = {
  processing: number;
  unseen: number;
};

export type TaskUrl = {
  url: string;
};

const search = (searchTerm: string): Promise<SearchResultItem[]> => {
  return httpClient.get(`/search?q=${searchTerm}`);
};

const tasks = async (): Promise<Task[]> => {
  return httpClient.get(`/me/tasks`);
};

const tasksCount = (): Promise<TaskCount> => {
  return httpClient.get(`/me/tasks/count`);
};

const tasksDownloadUrl = (
  taskId: string,
  actionIndex: number
): Promise<TaskUrl> => {
  return httpClient.get(`/me/tasks/sign?t=${taskId}&i=${actionIndex}`);
};

export default {
  search,
  tasks,
  tasksCount,
  tasksDownloadUrl,
};
