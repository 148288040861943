import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  styled,
} from "@mui/material";
import React from "react";

type Props = {
  icon: React.ReactElement;
  label: React.ReactNode;
  isActive?: boolean;
  selected?: boolean;
  component?: React.ElementType;
} & Omit<ListItemProps, "component">;

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{
  isActive?: boolean;
}>(({ theme, isActive }) => ({
  ...(isActive && {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  }),
}));

const SidebarItem = ({
  children,
  label,
  icon,
  isActive,
  selected,
  ...props
}: Props) => {
  return (
    <ListItem disablePadding {...props}>
      <StyledListItemButton
        isActive={isActive}
        selected={!isActive && selected}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
        {children}
      </StyledListItemButton>
    </ListItem>
  );
};

export default SidebarItem;
