export const DEFAULT_FULL_DATETIME_OPTIONS: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZone: "America/Sao_Paulo",
};

export default function formatDatetime(
  date: Date,
  lang = "pt-BR",
  options: Intl.DateTimeFormatOptions = DEFAULT_FULL_DATETIME_OPTIONS
) {
  const DateFormatter = new Intl.DateTimeFormat(lang, options);
  return DateFormatter.format(date);
}
