import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import StartIcon from "@mui/icons-material/Start";
import {
  useTheme,
  Button,
  CSSObject,
  Divider,
  Drawer,
  List,
  Skeleton,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useMemo, useState } from "react";

import getMenu from "./navLinks";
import SidebarLink from "./SidebarLink";

import { useAuth } from "@/modules/auth/AuthContext";

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.spacing(32),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  position: "relative",
  overflowX: "hidden",
  overflowY: "auto",
  zIndex: theme.zIndex.drawer,
  [theme.breakpoints.down("sm")]: {
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    paddingBottom: theme.spacing(10),
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: theme.spacing(7),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  position: "relative",
  overflowX: "hidden",
  overflowY: "hidden",
  zIndex: theme.zIndex.drawer,
  [theme.breakpoints.down("sm")]: {
    width: 0,
    position: "fixed",
  },
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const StyledMobileOpenDrawerButton = styled(Button)(({ theme }) => ({
  display: "flex",
  position: "fixed",
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: theme.zIndex.drawer + 1,
  minWidth: "unset",
  padding: theme.spacing(1.5),
  borderRadius: "50%",
  lineHeight: "initial",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

export const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  const menuItems = useMemo(() => {
    if (!user) {
      return [];
    }
    return getMenu(user.menu);
  }, [user]);

  return (
    <>
      <StyledDrawer
        variant="permanent"
        aria-label="Sidebar navigation"
        open={open}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <List disablePadding>
          {menuItems.map((linkProps) => (
            <li key={`${linkProps.label}-${linkProps.to}`}>
              <Divider />
              <SidebarLink onClick={() => setOpen(false)} {...linkProps} />
            </li>
          ))}
        </List>
      </StyledDrawer>
      <StyledMobileOpenDrawerButton
        onClick={() => setOpen(!open)}
        variant="contained"
      >
        {open ? (
          <KeyboardTabIcon style={{ rotate: "180deg" }} />
        ) : (
          <StartIcon />
        )}
      </StyledMobileOpenDrawerButton>
    </>
  );
};

export const SidebarSkeleton = () => {
  const theme = useTheme();
  const notMobile = useMediaQuery(theme.breakpoints.up("sm"));

  return notMobile ? (
    <StyledDrawer
      variant="permanent"
      aria-label="Sidebar navigation"
      open={false}
    >
      <List disablePadding>
        {Array.from(new Array(13)).map((_, index) => (
          <li key={index}>
            <Divider />
            <Skeleton
              variant="circular"
              width={32}
              height={32}
              sx={{
                mx: "auto",
                my: 1,
              }}
            />
          </li>
        ))}
        <Divider />
      </List>
    </StyledDrawer>
  ) : null;
};
